
















import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';
import SettingsModel from '@/models/crm/settings.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';

@Component({
  components: {},
})
export default class CrmImportProspects extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  @InjectReactive('settings') settings!: SettingsModel;

  htmlLink: string | null = null;

  isAdminOrRoot: boolean | null = null;

  private async loadHtmlPage(): Promise<void> {
    const urlView = 'crm/importacao-prospects';
    const baseUrl = this.settings.urlAcessoGeo360;
    this.htmlLink = `${baseUrl}/${urlView}?t=${this.sessionService.apiToken}`;
  }

  onClickPrevious(): void {
    this.$router.back();
  }

  async mounted(): Promise<void> {
    this.loadHtmlPage();
    this.isAdminOrRoot = this.sessionService.isAdmOrRoot;
  }
}
